var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800px", "no-click-animation": "", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Reschedule Trip Request")]),
          _c(
            "v-card-text",
            { staticClass: "pt-5" },
            [
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.reschedule } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("date-picker", {
                            ref: "leaveDate",
                            attrs: {
                              label: "Leave Date",
                              rules: [
                                () =>
                                  !!_vm.leaveDate || "This field is required",
                              ],
                              required: "",
                              hint: `Currently ${_vm.tripRequest.leaveDate}`,
                              persistentHint: true,
                            },
                            model: {
                              value: _vm.leaveDate,
                              callback: function ($$v) {
                                _vm.leaveDate = $$v
                              },
                              expression: "leaveDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("time-picker", {
                            ref: "leaveTime",
                            attrs: {
                              label: "Leave Time",
                              rules: [
                                () =>
                                  !!_vm.leaveTime || "This field is required",
                              ],
                              required: "",
                              hint: `Currently ${_vm.readableTime(
                                _vm.tripRequest.leaveTime
                              )}`,
                              persistentHint: true,
                            },
                            model: {
                              value: _vm.leaveTime,
                              callback: function ($$v) {
                                _vm.leaveTime = $$v
                              },
                              expression: "leaveTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("date-picker", {
                            ref: "returnDate",
                            attrs: {
                              label: "Return Date",
                              rules: [
                                () =>
                                  !!_vm.returnDate || "This field is required",
                              ],
                              required: "",
                              hint: `Currently ${_vm.tripRequest.returnDate}`,
                              persistentHint: true,
                            },
                            model: {
                              value: _vm.returnDate,
                              callback: function ($$v) {
                                _vm.returnDate = $$v
                              },
                              expression: "returnDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("time-picker", {
                            ref: "returnTime",
                            attrs: {
                              label: "Return Time",
                              rules: [
                                () =>
                                  !!_vm.returnTime || "This field is required",
                              ],
                              required: "",
                              hint: `Currently ${_vm.readableTime(
                                _vm.tripRequest.returnTime
                              )}`,
                              persistentHint: true,
                            },
                            model: {
                              value: _vm.returnTime,
                              callback: function ($$v) {
                                _vm.returnTime = $$v
                              },
                              expression: "returnTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _vm.me.is.superAdmin || _vm.me.is.transportationAdmin
                        ? [
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c(
                                  "v-radio-group",
                                  {
                                    attrs: { row: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c("div", [
                                                _vm._v(
                                                  "Retain current vehicle and driver assignments?"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3061625513
                                    ),
                                    model: {
                                      value: _vm.retainAssignments,
                                      callback: function ($$v) {
                                        _vm.retainAssignments = $$v
                                      },
                                      expression: "retainAssignments",
                                    },
                                  },
                                  [
                                    _c("v-radio", {
                                      attrs: { label: "Yes", value: true },
                                    }),
                                    _c("v-radio", {
                                      attrs: { label: "No", value: false },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-col", { attrs: { cols: "12" } }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-subtitle-1 font-weight-bold amber--text text--darken-2",
                                },
                                [
                                  _vm._v(
                                    ' Please note that by clicking "Yes" here, the vehicle and driver availability will not be checked '
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._l([..._vm.activeMessages], function (am) {
                    return _c(
                      "v-row",
                      { key: am.id, attrs: { dense: "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "12" } },
                          [
                            _c(
                              "v-alert",
                              {
                                attrs: {
                                  outlined: "",
                                  type: am.alertType || "info",
                                  text: "",
                                },
                              },
                              [_vm._v(_vm._s(am.message))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "success",
                    disabled: _vm.prevent,
                    loading: _vm.saving,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.reschedule()
                    },
                  },
                },
                [_vm._v(" Reschedule ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }